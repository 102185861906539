/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import { Fragment } from "react"
import Img from "gatsby-image"

import AnimatedLink from "../components/animated-link"
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa"

const PortfolioCard = ({ article }) => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const boxShadow = isDark
    ? `rgba(50, 33, 84, 0.25)`
    : `rgba(135, 187, 162, 0.25)`

  // const boxShadow = isDark
  //   ? `rgba(95, 82, 122, 0.25)`
  //   : `rgba(153, 188, 255, 0.15)`

  return (
    <li
      sx={{
        width: "100%",
        maxWidth: ["600px", "600px", "600px", "960px"],
        height: ["425px", "425px", "425px", "250px"],
        justifyContent: "space-between",
        justifySelf: "center",
        listStyle: "none",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: ["column", "column", "column", "row"],
        borderRadius: "5px",
        boxShadow: `5px 5px 9px ${boxShadow}`,
      }}
    >
      <div
        sx={{
          width: ["100%", "100%", "100%", "100%", "40%"],
          maxWidth: ["350px", "500px", "600px", "600px"],
          height: ["50%", "50%", "50%", "100%"],
          marginBottom: ["1rem", "1rem", "0", "0"],
          marginRight: ["0", "0", "0", "1rem"],
          alignSelf: "center",
          display: "flex",
        }}
      >
        <Img
          fluid={article.frontmatter.hero.childImageSharp.fluid}
          alt={`${article.frontmatter.hero.childImageSharp.fluid.originalName
            .split("-")
            .join(" ")
            .slice(0, -4)}`}
          style={{
            width: "100%",
            // borderRadius: "5px",
          }}
          imgStyle={{
            objectFit: "contain",
          }}
        />
      </div>
      <div
        sx={{
          width: ["100%", "100%", "100%", "100%", "60%"],
          maxWidth: ["600px", "600px", "600px", "600px", "70ch"],
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "8px 16px",
          margin: "0.5rem 0",
        }}
      >
        <div>
          <h3
            sx={{
              margin: "0",
              fontSize: ["1.15rem", "1.25rem", "1.5rem"],
            }}
          >
            <AnimatedLink
              isInternal={true}
              href={article.fields.slug}
              styles={{
                backgroundImage: theme =>
                  `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`,
              }}
            >
              {article.frontmatter.title}
            </AnimatedLink>
          </h3>

          <small>{article.frontmatter.stack}</small>
          <p>{article.frontmatter.description}</p>
          {/* <p>{article.frontmatter.summary}</p> */}
        </div>

        <div
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              left: "-10px",
            }}
          >
            {article.frontmatter.github ? <Fragment><a
              href={article.frontmatter.github}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "46px",
                minWidth: "46px",
                height: "46px",
              }}
            >
              <FaGithub
                sx={{
                  fontSize: "1.5rem",
                  color: "text",
                  transition: "color 0.2s ease-in-out",

                  "&:hover": {
                    color: "secondary",
                  },
                }}
              />
            </a>
            | </Fragment>: null}
            <a
              href={article.frontmatter.url}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "46px",
                minWidth: "46px",
                height: "46px",
              }}
            >
              <FaExternalLinkAlt
                sx={{
                  fontSize: "1.25rem",
                  color: "text",
                  transition: "color 0.2s ease-in-out",

                  "&:hover": {
                    color: "secondary",
                  },
                }}
              />
            </a>
          </div>
          <AnimatedLink
            isInternal={true}
            href={article.fields.slug}
            styles={{
              backgroundImage: theme =>
                `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`,
            }}
          >
            Read more »
          </AnimatedLink>
        </div>
      </div>
    </li>
  )
}

export default PortfolioCard
