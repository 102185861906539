/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

// https://nickymeuleman.netlify.app/blog/gatsby-pagination/
const PostListPagination = ({
  prevPage,
  nextPage,
  numPages,
  isFirst,
  isLast,
  currentPage,
}) => {
  return (
    <div
      sx={{
        width: "100%",
        maxWidth: "calc(70ch + 350px + 32px)",
        display: "grid",
        gridTemplateColumns: "65px minmax(100px, 1fr) 65px",
        gridGap: "1rem",
        alignItems: "center",
        fontSize: ["1rem", "1.1rem"],
        marginTop: "2rem",
      }}
    >
      {!isFirst && (
        <Link
          to={prevPage}
          rel="prev"
          sx={{
            variant: `styles.animateUnderline`,
            width: "fit-content",
            justifySelf: "flex-start",
            gridColumn: "1",
            backgroundImage: theme =>
              `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(${theme.colors.lightened}, ${theme.colors.lightened})`,

            span: {
              variant: `styles.animateUnderline.span`,
              backgroundImage: theme => `linear-gradient(
                              to right,
                              ${theme.colors.lightened},
                              ${theme.colors.lightened} 50%,
                              ${theme.colors.text} 50%)`,
            },
          }}
        >
          <span>← Prev</span>
        </Link>
      )}

      {/* add pagination numbers */}
      <div
        sx={{
          width: "100%",
          maxWidth: "300px",
          height: "auto",
          maxHeight: "2rem",
          gridColumn: "2",
          display: "flex",
          justifyContent: "space-evenly",
          justifySelf: "center",
          overflowX: "scroll",
        }}
      >
        {Array.from({ length: numPages }, (_, i) => (
          <Link
            key={`pagination-number${i + 1}`}
            to={`/blog/${i === 0 ? "" : i + 1}`}
            sx={{
              gridColumn: "3",
              padding: "2px 8px",
              boxSizing: "border-box",
              textDecoration: "none",
              borderBottom: theme =>
                i + 1 === currentPage
                  ? `2px solid ${theme.colors.lightened}`
                  : `2px solid ${theme.colors.text}`,
              color: "text",
              transition: "all .2s ease-out",
              bg: i + 1 === currentPage ? "lightened" : "background",
              borderRadius: i + 1 === currentPage ? "3px" : "0",

              "&:hover": {
                borderBottom: theme => `2px solid ${theme.colors.lightened}`,
                bg: "lightened",
                borderRadius: "3px",
              },
            }}
          >
            <span>{i + 1}</span>
          </Link>
        ))}
      </div>

      {!isLast && (
        <Link
          to={nextPage}
          rel="next"
          sx={{
            variant: `styles.animateUnderline`,
            width: "fit-content",
            justifySelf: "flex-end",
            gridColumn: "3",
            backgroundImage: theme =>
              `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(${theme.colors.lightened}, ${theme.colors.lightened})`,

            span: {
              variant: `styles.animateUnderline.span`,
              backgroundImage: theme => `linear-gradient(
                              to right,
                              ${theme.colors.lightened},
                              ${theme.colors.lightened} 50%,
                              ${theme.colors.text} 50%)`,
            },
          }}
        >
          <span>Next →</span>
        </Link>
      )}
    </div>
  )
}

export default PostListPagination
