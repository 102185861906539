/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import PortfolioCard from "../components/portfolio-card"
import Pagination from "../components/post-list-pagination"

const PortfolioListTemplate = ({
  data,
  pageContext: { currentPage, numPages },
}) => {
  const articles = data.allMdx.edges

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? "/portfolio"
      : `/portfolio/${(currentPage - 1).toString()}`
  const nextPage = `/portfolio/${(currentPage + 1).toString()}`

  return (
    <div
      sx={{
        width: "100%",
        maxWidth: "calc(70ch + 350px + 32px)",
        position: "relative",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridRowGap: "32px",
        margin: "0 auto",
        padding: "0 1.5rem",
      }}
    >
      <ul
        sx={{
          padding: "0",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridRowGap: "2rem",
        }}
      >
        {articles.map(({ node }) => (
          <PortfolioCard key={node.fields.slug} article={node} />
        ))}
      </ul>

      <Pagination
        prevPage={prevPage}
        nextPage={nextPage}
        numPages={numPages}
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
      />
    </div>
  )
}

PortfolioListTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PortfolioListTemplate

export const listQuery = graphql`
  query portfolioListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "portfolio-list-template" } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            section
            hero {
              childImageSharp {
                fluid(maxWidth: 675) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
            description
            url
            github
            stack
          }
        }
      }
    }
  }
`
